
import { Component, Vue, Prop } from 'vue-property-decorator';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { parseDate } from 'element-ui/src/utils/date-util';

@Component
export default class YkTimeSelect extends Vue {
  @Prop({ type: Array, required: true })
  options!: { label: string; value: string }[];

  onChange(value: string) {
    const time = parseDate(value, 'HH:mm');
    this.$emit('change', time);
  }
}
