import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

const path = '/consumerUser';
export default [
  {
    path: path,
    name: '运营',
    component: Layout,
    redirect: '/consumerUser/index',
    meta: {
      title: 'C端用户管理',
      icon: 'ph:user-list',
      code: '300ARgbgfizn'
    },
    children: [
      {
        path: '/consumerUser/index',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/consumerUser/index.vue'),
        name: 'consumerUser',
        meta: {
          title: '用户列表',
          code: '2Jrrsb0yvRDj'
        }
      },
      {
        path: '/consumerUser/detail/:consumerUserId',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/consumerUser/detail.vue'),
        name: 'consumerUserDetail',
        meta: {
          title: '用户列表',
          hidden: true
        }
      },
      {
        path: '/consumerTag/index',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/consumerTag/index.vue'),
        name: 'consumerTag',
        meta: {
          title: '用户画像管理',
          code: '2YvKmoje9hbz'
        }
      },
      {
        path: '/consumerTag/detail/:portraitId?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/consumerTag/detail.vue'),
        name: 'consumerTag',
        meta: {
          title: '用户画像管理',
          hidden: true
        }
      },
      {
        path: '/consumerPush/index',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/consumerPush/index.vue'),
        name: 'consumerPush',
        meta: {
          title: '用户推送管理',
          code: '2Jw14eNVPwPx'
        }
      },
      {
        path: '/consumerPush/detail/:baNumber?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/consumerPush/detail.vue'),
        name: 'consumerPush',
        meta: {
          title: '用户通知管理',
          hidden: true
        }
      }
    ]
  }
] as RouteConfig[];
