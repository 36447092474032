import { render, staticRenderFns } from "./YkFormLabel.vue?vue&type=template&id=6836d89c&scoped=true&"
import script from "./YkFormLabel.vue?vue&type=script&lang=ts&"
export * from "./YkFormLabel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_sqwtrzbl6s4ypz4dmnoirtpj7i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6836d89c",
  null
  
)

export default component.exports