import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { matchRouteMenu } from './../../matchRouteMenu';
import { setCode, setWatermark } from '@/utils/cookies';
import { UserModule } from '@/store/modules/user';
import { ApiLogin, ApiGetUserinfo, ApiPermissions } from '@/api/login/index'; // 导入接口

/* Layout */
import Layout from '@/layout/index.vue';
Vue.use(VueRouter);
export let serviceRoutes: RouteConfig[] = [];
// if (process.env.NODE_ENV === 'development') {
const routerContext = (require as any).context('@/router/modules', true, /[\\/][^_][^\\/]+\.ts$/);

routerContext.keys().forEach((route: any) => {
  const routerModule = routerContext(route);
  serviceRoutes = [...serviceRoutes, ...(routerModule.default || routerModule)];
});

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Layout',
    redirect: '/personal/index'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'Login' */ '@/pages/login/index.vue'),
    meta: {
      hidden: true,
      code: true
    }
  },
  {
    path: '/index',
    name: 'home',
    component: () => import(/* webpackChunkName: 'Login' */ '@/pages/home/index.vue'),
    meta: {
      hidden: true,
      code: true
    }
  },
  {
    path: '/noAccess',
    name: 'noAccess',
    component: () => import(/* webpackChunkName: 'Login' */ '@/pages/noAccess/index.vue'),
    meta: {
      hidden: true,
      code: true
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: 'Login' */ '@/pages/error-page/404.vue'),
    meta: {
      hidden: true
      // code: '404'
    }
  },
  {
    path: '/403',
    name: '403',
    component: () => import(/* webpackChunkName: 'Login' */ '@/pages/error-page/403.vue'),
    meta: {
      hidden: true
      // code: '403'
    }
  },
  ...serviceRoutes
];
const router = new VueRouter({
  mode: 'hash',
  routes
});
const params = {
  client_id: 'magmeta',
  client_secret: 'secret',
  grant_type: 'auth_web_feishu',
  code: '',
  redirectUri: `${process.env.REDIRECT_URL}`
};

// 路由守卫
router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (window.location.search && !UserModule.token) {
    params.code = window.location.search.split('=')[1];
    setCode(params.code);
    const {
      data: { data }
    } = await ApiLogin(params);
    UserModule.setToken(data.access_token);
    setWatermark(data.watermark);
    if (data.isNew || data.scope === 'ROLE_UNAUTHORIZED') {
      location.replace(location.href.replace(location.search, ''));
      location.replace(location.href.replace(location.hash, '#/index'));
    } else {
      location.replace(location.href.replace(location.search, ''));
      location.replace(location.href.replace(location.hash, '#/user/index'));
    }
  }
  matchRouteMenu(to, from, next);
});

export default router;
