
import { AppModule } from '@/store/modules/app';
// import { IRouteObj } from '@/types/routeTypes';
import { Component, Vue, Watch } from 'vue-property-decorator';
import Breadcrumb from '../Breadcrumb/index.vue';

import { ECloseTab } from './types';
@Component({
  components: {
    Breadcrumb
  }
})
export default class Tab extends Vue {
  isShow = false;
  path = '';
  /**
   * 关闭按钮的枚举
   */
  public ECloseTab = ECloseTab;
  // 获取TabList
  get TabList() {
    return AppModule.TabList;
  }

  /**
   * 获取当前选中的值，需要使用计算属性
   */
  get currentTab() {
    return AppModule.currentTab;
  }

  @Watch('$route')
  public onRouteChange(route: any) {
    if (route.name === 'consumerUserDetail') {
      this.isShow = true;
      this.path = '/consumerUser/index';
    } else if (route.name === 'deviceDetail') {
      this.isShow = true;
      this.path = '/devices/index';
    } else if (route.name === 'bannerSort') {
      this.isShow = true;
      this.path = '/banner/index';
    } else if (route.name === 'calendar') {
      this.isShow = true;
      this.path = '/banner/index';
    } else if (route.name === '形成因素') {
      this.isShow = true;
      this.path = '/reportFile/index';
    } else if (route.name === '变化报告标签') {
      this.isShow = true;
      this.path = '/reportFile/index';
    } else if (route.name === '方案-首次报告') {
      this.isShow = true;
      this.path = '/reportFile/index';
    } else if (route.name === '方案-变化报告') {
      this.isShow = true;
      this.path = '/reportFile/index';
    } else if (route.name === '未发布题组') {
      this.isShow = true;
      this.path = '/questions/index';
    } else if (route.name === '已上线发布题组') {
      this.isShow = true;
      this.path = '/questions/index';
    } else {
      this.isShow = false;
    }
  }

  mounted() {
    if (this.$route.name === 'consumerUserDetail') {
      this.isShow = true;
      this.path = '/consumerUser/index';
    } else if (this.$route.name === 'deviceDetail') {
      this.isShow = true;
      this.path = '/devices/index';
    } else if (this.$route.name === 'bannerSort') {
      this.isShow = true;
      this.path = '/banner/index';
    } else if (this.$route.name === 'calendar') {
      this.isShow = true;
      this.path = '/banner/index';
    } else if (this.$route.name === '形成因素') {
      this.isShow = true;
      this.path = '/reportFile/index';
    } else if (this.$route.name === '变化报告标签') {
      this.isShow = true;
      this.path = '/reportFile/index';
    } else if (this.$route.name === '方案-首次报告') {
      this.isShow = true;
      this.path = '/reportFile/index';
    } else if (this.$route.name === '方案-变化报告') {
      this.isShow = true;
      this.path = '/reportFile/index';
    } else if (this.$route.name === '未发布题组') {
      this.isShow = true;
      this.path = '/questions/index';
    } else if (this.$route.name === '已上线发布题组') {
      this.isShow = true;
      this.path = '/questions/index';
    } else {
      this.isShow = false;
    }
  }
}
