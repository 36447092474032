// import { TIRUserData } from '@/api/login/types';
// import { getUserData } from '@/utils/cookies';
import { UserModule } from '@/store/modules/user';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import { ApiGetUserinfo } from '@/api/login/index'; // 导入接口
const whitePage = ['/login', '/404', '/403'];
// 路由守卫
export async function matchRouteMenu(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  // 如果有toke
  // 判断是否要去登录页
  // console.log(UserModule.token);
  if (UserModule.token) {
    if (to.path === '/login') {
      next('/');
    } else {
      // 如果去的页面非登陆页
      //     如果有用户信息，不调用接口正常通过
      //     如果没有用户信息，调用接口获取用户信息，且在对比权限后执行正常通过
      if (!UserModule.useData?.id) {
        next();
      } else {
        const {
          data: { data }
        } = await ApiGetUserinfo();

        next();
        if (data.permissionCodes.includes(to.meta?.code) || to.meta?.code === true) {
          next();
        } else if (!data.permissionCodes.includes(to.meta?.code) && to.meta?.code) {
          next('/403');
        } else if (to.name === null) {
          next('/404');
        }
      }
    }
  } else {
    if (whitePage.includes(to.path.toLowerCase())) {
      next();
    } else {
      next('/login');
    }
  }
}
