import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { getUserData, getToken, setToken, removeToken, setUserData, setMenuName, getMenuName } from '@/utils/cookies';
import { IUserData } from '@/types/dataTypes';
import { RouteConfig } from 'vue-router';

export type IUserState = {
  // 用户数据
  useData: IUserData | null;
  // 令牌
  token: string;
  /**
   * 路由列表
   */
  routeList: RouteConfig[];
  // 用户权限
  permissionCodes: string[];
};

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  // 路由列表
  public routeList!: RouteConfig[];
  // 用户数据
  public useData = null as null | IUserData;
  // token
  // public token = localStorage.getItem('token') || '';
  public token = getToken() || '';

  // private permits = [] as { id: number }[]
  public permits = [] as { id: number; name: string; code: string }[];
  permissionCodes = [] as string[];
  routerPath = [] as string[];
  menuName = getMenuName() || '';

  /**
   * 存入用户数据
   * @param userData
   */
  @Mutation
  public SET_USER_DATA(userData: any) {
    // 存入数据
    this.useData = userData;
    // 存入数据进入cookie
    setUserData(userData);
  }

  /**
   * 移除token
   */
  @Mutation
  DEL_TOKEN() {
    this.token = '';
    // localStorage.removeItem('token');
    removeToken();
  }

  /**
   * 存入token
   * @param token
   */
  @Mutation
  public SET_TOKEN(token: string) {
    this.token = token;
    setToken(token);
  }

  @Mutation
  public SET_MENU(routeList: RouteConfig[]) {
    this.routeList = routeList;
  }

  @Mutation
  public SET_PERMITS(list: any[]) {
    this.permits = list;
  }

  @Mutation
  public SET_PERMISSIONCODES(list: string[]) {
    this.permissionCodes = list;
  }

  @Mutation
  public SET_ROUTEPATH(list: string[]) {
    this.routerPath = list;
  }

  @Mutation
  public SET_MENUNAME(name: string) {
    this.menuName = name;
    setMenuName(name);
  }

  /**
   * 对外暴露设置用户数据
   * @param userData
   */
  @Action
  public setUserData(userData: IUserData) {
    this.SET_USER_DATA(userData);
    this.SET_PERMITS(userData.menus);
    this.SET_PERMISSIONCODES(userData.permissionCodes);
    // setMenus(userData.permissionCodes);
  }

  /**
   * 对外暴露存入token
   * @param token
   */
  @Action
  public setToken(token: string) {
    // localStorage.setItem('token', token);
    setToken(token);
    this.SET_TOKEN(token);
  }

  /**
   * 对外暴露setRoutePath
   * @param routerPath
   */
  @Action
  public setRoutePath(path: string[]) {
    this.SET_ROUTEPATH(path);
  }

  /**
   * 对外暴露setRoutePath
   * @param menuName
   */
  @Action
  public setMenuName(name: string) {
    this.SET_MENUNAME(name);
  }

  /**
   * 获取用户的token
   * @returns
   */
  @Action
  public getToken() {
    return this.token;
  }

  /**
   * 获取用户菜单
   * @returns
   */
  @Action
  public async getMenu() {
    const userData: IUserData = JSON.parse(getUserData() as string);
    // return await APIGetMenu({
    //   phone: userData.phone,
    // });
  }

  /**
   * 存入路由数据
   * @param routeList
   */
  @Action setMenu(routeList: RouteConfig[]) {
    this.SET_MENU(routeList);
  }

  /**
   * 移除token
   */
  @Action
  deltoken() {
    this.DEL_TOKEN();
  }

  get hadPermit() {
    return (code?: string) => {
      if (code === undefined) return true;
      return this.permissionCodes.some(item => item === code);
    };
  }

  get Menus() {
    return this.permissionCodes;
  }
}

export const UserModule = getModule(User);
