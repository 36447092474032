import { render, staticRenderFns } from "./YkTablePoptip.vue?vue&type=template&id=5215d8f6&"
import script from "./YkTablePoptip.vue?vue&type=script&lang=ts&"
export * from "./YkTablePoptip.vue?vue&type=script&lang=ts&"
import style0 from "./YkTablePoptip.vue?vue&type=style&index=0&id=5215d8f6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_sqwtrzbl6s4ypz4dmnoirtpj7i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports