
import { Component, Vue, Prop } from 'vue-property-decorator';
import OSS from 'ali-oss';
import service from '@/api/consumerUser';

@Component({ components: {} })
export default class ViewImage extends Vue {
  @Prop({ type: String, required: false })
  src!: string;

  @Prop({ type: Array, required: false })
  previewSrcList!: [];

  @Prop({ type: String, required: false, default: '100%' })
  width!: '';

  @Prop({ type: String, required: false, default: '100%' })
  height!: '';

  @Prop({ type: String, required: false })
  fit!: '';

  @Prop({ type: String, required: false })
  alt!: '';

  @Prop({ type: String, required: false })
  referrerPolicy!: '';

  @Prop({ type: Number, required: false, default: 2000 })
  zIndex!: '';

  @Prop({ type: String, required: false })
  mode!: string;

  @Prop({ type: String, required: false })
  path!: string;

  wrapperElem = null as any;
  hidElClassNameLists = ['el-image-viewer__mask', 'el-image-viewer__btn el-image-viewer__close', 'el-icon-close'];

  checkElemlents() {
    this.$nextTick(() => {
      const img = document.querySelector('.el-image-viewer__actions__inner');
      const out = document.querySelector('.el-icon-zoom-out');
      const screen = document.querySelector('.el-icon-full-screen');
      const refreshL = document.querySelector('.el-icon-refresh-left');
      const divider = document.querySelector('.el-image-viewer__actions__divider');
      const iconclose = document.querySelector('.el-image-viewer__close') as HTMLElement | null;
      const iconprev = document.querySelector('.el-image-viewer__prev') as HTMLElement | null;
      const iconnext = document.querySelector('.el-image-viewer__next') as HTMLElement | null;
      const mask = document.querySelector('.el-image-viewer__mask') as HTMLElement | null;
      const actions = document.querySelector('.el-image-viewer__actions') as HTMLElement | null;
      const canvas = document.querySelector('.el-image-viewer__canvas') as HTMLElement | null;
      const actionsclose = document.querySelector('.el-image-viewer__close .el-icon-close') as HTMLElement | null;
      iconclose!.style.opacity = '0';
      iconclose!.style.top = '6px';
      iconclose!.style.right = '17px';
      iconclose!.style.width = '50px';
      if (iconprev) {
        iconprev.style.visibility = 'hidden';
      }
      if (iconnext) {
        iconnext.style.visibility = 'hidden';
      }
      if (this.mode === 'drawer') {
        mask!.setAttribute('class', 'el-image-viewer__mask w-150 left-auto right-0 bg-white top-11.5 opacity-100');
        actions!.setAttribute('class', 'el-image-viewer__btn el-image-viewer__actions  right-0 bottom-20 left-auto');
        canvas!.setAttribute('class', 'el-image-viewer__canvas justify-end pr-37.5');
        actionsclose!.setAttribute('class', 'el-icon-setting text-6xl');
      } else if (this.mode === 'global') {
        mask!.setAttribute(
          'class',
          'el-image-viewer__mask w-[calc(88%-0.7rem)] left-56.5 right-0 bg-white top-45 bottom-25 opacity-100'
        );
      }
      const left = document.createElement('i');
      const right = document.createElement('i');
      const down = document.createElement('i');
      const close = document.createElement('i');
      if (this.previewSrcList.length > 1) {
        left.setAttribute('class', 'el-icon-arrow-left cursor-pointer');
        right.setAttribute('class', 'el-icon-arrow-right cursor-pointer');
      }
      down.setAttribute('class', 'el-icon-download cursor-pointer');
      close.setAttribute('class', 'el-icon-close cursor-pointer');
      img!.appendChild(down);
      img!.appendChild(close);
      out!.parentNode!.insertBefore(left, out);
      out!.parentNode!.insertBefore(right, out);
      img!.removeChild(screen!);
      img!.removeChild(refreshL!);
      img!.removeChild(divider!);
      const wrapper = document.getElementsByClassName('el-image-viewer__wrapper');
      if (wrapper.length > 0) {
        this.wrapperElem = wrapper[0];
        this.cusClickHandler();
      } else {
        this.checkElemlents();
      }
    });
  }

  cusClickHandler() {
    this.wrapperElem!.addEventListener('click', this.hideCusBtn);
  }

  async hideCusBtn(e: any) {
    const className = e.target.className;
    if (className.includes('el-icon-setting')) {
      this.$emit('closeViewer');
      return;
    }
    if (className.includes('el-icon-download')) {
      this.dnImage();
    } else if (className === 'el-icon-arrow-left') {
      const prev = document.querySelector('.el-image-viewer__prev') as HTMLElement | null;
      prev!.click();
    } else if (className === 'el-icon-arrow-right') {
      const next = document.querySelector('.el-image-viewer__next') as HTMLElement | null;
      next!.click();
    } else if (className.includes('el-icon-close')) {
      const close = document.querySelector('.el-image-viewer__close') as HTMLElement | null;
      close!.click();
    }
  }

  dnImage() {
    const imgUrl = document.getElementsByClassName('el-image-viewer__canvas')[0].children[0] as HTMLImageElement;
    const url = imgUrl.src;
    this.downloadImage(url);
  }

  async download(imgsrc: any) {
    const URL = imgsrc.split('?')[0].split('/');
    const fileName = URL[URL.length - 1];
    const {
      data: { data }
    } = await service.getOSSToken(`user/${this.path}/upload`);
    if (data) {
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: data.accessKeyId,
        accessKeySecret: data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: data.securityToken,
        // 填写Bucket名称。
        bucket: data.bucketName
      });
      // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
      const response = {
        'content-disposition': `attachment; filename=${encodeURIComponent(fileName)}`
      };
      // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
      const url = client.signatureUrl(data.pathPrefix + fileName, { response });
      const a = document.createElement('a');
      a.href = url;
      a.click();
      a.remove();
    }

    // 下载图片地址和图片名
    // const image = new Image();
    // // 解决跨域 Canvas 污染问题
    // image.setAttribute('crossOrigin', 'anonymous');
    // image.onload = () => {
    //   const canvas = document.createElement('canvas');
    //   canvas.width = image.width;
    //   canvas.height = image.height;
    //   const context = canvas.getContext('2d');
    //   context!.drawImage(image, 0, 0, image.width, image.height);
    //   canvas.toBlob(blob => {
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.download = name || 'photo';
    //     a.href = url;
    //     a.click();
    //     a.remove();
    //     URL.revokeObjectURL(url);
    //   });
    // };
    // image.src = imgsrc;
  }

  downloadImage(imgUrl: any) {
    this.download(imgUrl);
  }
}
