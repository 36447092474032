import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

const path = '/platform';
export default [
  {
    path: path,
    name: '系统',
    component: Layout,
    redirect: '/user/index',
    meta: {
      title: '平台管理',
      icon: 'ic:round-view-headline',
      code: '2JAiDIJh0hxB'
    },
    children: [
      {
        path: '/user/index',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/user/index.vue'),
        name: 'user',
        meta: {
          title: 'B端账号管理',
          code: '2JvWZElCfwKW'
        }
      },
      {
        path: '/user/detail/:userId',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/user/detail.vue'),
        name: 'user',
        meta: {
          title: '编辑账号',
          hidden: true
        }
      },
      {
        path: '/role/index',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/role/index.vue'),
        name: 'role',
        meta: {
          title: '角色权限管理',
          code: '2Q0A1RFx6DoG'
        }
      },
      {
        path: '/log/index',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/log/index.vue'),
        name: 'log',
        meta: {
          title: '日志管理',
          code: '2Uuq25KlFrXP'
        }
      },
      {
        path: '/personal/index',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/personal/index.vue'),
        name: 'personal',
        meta: {
          title: '个人资料',
          hidden: true,
          code: true
        }
      }
    ]
  }
] as RouteConfig[];
