
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RouteRecord, Route, NavigationGuardNext } from 'vue-router';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { routes } from '@/router';
import { removeWatermark } from '@/utils/watermark';
import { removeCode, setWatermark, setUserPath, removeSkinType } from '@/utils/cookies';
import { ApiGetUserinfo, ApiPermissions, ApiLogout } from '@/api/login/index'; // 导入接口
@Component({
  components: {}
})
export default class Navbar extends Vue {
  dialogFlag = false;
  permissions = [] as any;
  isActive = '' as any;
  public breadcrumbs: RouteRecord[] = [];
  systemPath = [] as string[];
  operationPath = [] as string[];
  Active(name: string) {
    this.isActive = name;
    UserModule.setMenuName(name);
    if (name === '系统' && !this.systemPath[0].includes(this.$router.currentRoute.name!)) {
      this.$router.push(this.systemPath[0]);
    } else if (name === '运营' && !this.operationPath[0].includes(this.$router.currentRoute.name!)) {
      this.$router.push(this.operationPath[0]);
    }
  }

  changeIsActive() {
    this.systemPath = [];
    this.operationPath = [];
    const route = routes.filter((item: any) => item.children);
    this.userInfo.permissionCodes.forEach((permission: any) => {
      route.forEach((route: any) => {
        route.children.forEach((child: any) => {
          if (route.name === '系统' && child.meta.code === permission) {
            this.systemPath.push(child.path);
          } else if (route.name === '运营' && child.meta.code === permission) {
            this.operationPath.push(child.path);
          }
        });
      });
    });
    setUserPath(this.systemPath[0]);
  }

  get routes() {
    return routes;
  }

  @Watch('$route')
  public onRouteChange(route: Route) {
    if (route.path.startsWith('/redirect/')) {
      return;
    }
    this.getBreadcrumb();
  }

  public getBreadcrumb() {
    const matched = this.$route.matched.filter(item => item.meta && item.meta.title);
    this.breadcrumbs = matched.filter(item => {
      return item.meta && item.meta.title && item.meta.breadcrumb !== false;
    });
    this.isActive = this.breadcrumbs[0].name;

    UserModule.setMenuName(this.isActive);
  }

  /**
   * 获取权限路由
   */
  get hasPermit() {
    return UserModule.hadPermit;
  }

  get sidebar() {
    return AppModule.sidebar;
  }

  get userInfo() {
    return UserModule.useData ?? ({} as any);
  }

  get avatar() {
    return require('@/assets/avatar.gif');
  }

  async created() {
    this.getBreadcrumb();
    if (UserModule.token && !UserModule.useData?.id) {
      const {
        data: { data }
      } = await ApiGetUserinfo();

      UserModule.setUserData(data);
      // NOTE 本地环境 获取用户信息中的水印数据
      setWatermark(data.watermark);
    }

    this.changeIsActive();
    this.getList();
  }

  async getList() {
    const {
      data: { data }
    } = await ApiPermissions();

    this.permissions = data.filter((item: any) => !item.name.includes('标记'));
    this.permissions.forEach((item: any) => {
      if (item.name === '系统') {
        item.icon = 'icon-park-solid:setting-two';
      } else if (item.name === '运营') {
        item.icon = 'mdi:bullseye-arrow';
      } else if (item.name === '数据') {
        item.icon = 'mdi:file-document-outline';
      }
    });
    if (this.$router.currentRoute.name === 'personal') {
      this.$router.push(UserModule.routerPath[0]);
    }
  }

  public toggleSideBar() {
    AppModule.ToggleSideBar(false);
  }

  public async done() {
    const {
      data: { msg }
    } = await ApiLogout();
    if (msg === '操作成功') {
      removeCode();
      removeWatermark();
      removeSkinType();
      UserModule.deltoken();
      this.$router.push('/login');
    }
  }
}
