
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SpDialog extends Vue {
  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, default: 'confirm' })
  type!: 'alert' | 'confirm';

  @Prop({ type: String, required: false })
  size?: 'h-full' | 'w-full' | 'full';

  @Prop({ type: Function, required: true })
  onOk?: YkFunction;

  @Prop({ type: String })
  okText?: string;

  @Prop({ type: [String, Boolean], default: 'bottom' })
  actionPosition?: 'top' | 'bottom' | false;

  private mixinLoading = false;
  async onSave() {
    this.mixinLoading = true;
    try {
      await this.onOk?.();
    } finally {
      this.mixinLoading = false;
    }
  }

  private get attrs() {
    return Object.assign(
      {
        width: '800px',
        'show-close': false,
        'close-on-click-modal': false,
        'close-on-press-escape': false,
        'destroy-on-close': true,
        'append-to-body': true
      },
      this.$attrs
    );
  }
}
