import request from '@/utils/request';
const headers = {
  'accept-language': 'zh-Hans-CN',
  from: 1
};
const service = {
  query(params: any, data: any) {
    return request.post(`/api/user/v1/user/cList?pageNum=${params.pageNum}&pageSize=${params.pageSize}`, data);
  },
  detail(id: any) {
    return request.post(`/api/user/v1/user/getCById/${id}`);
  },
  saveTags(data: any) {
    return request.post('/api/user/v1/user/tag/saveTags', data);
  },
  getTagList(params: any, data: any) {
    return request({
      method: 'POST',
      url: `/api/user/v1/tag/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
      headers: headers,
      data
    });
  },
  queryRemarks(params: any) {
    return request.post(`/api/user/v1/user/remarks/all?userId=${params.id}`);
  },
  addRemark(data: any) {
    return request.post('/api/user/v1/user/remarks/add', data);
  },
  updateRemark(data: any) {
    return request.post('/api/user/v1/user/remarks/update', data);
  },
  delRemark(data: any) {
    return request.post(`/api/user/v1/user/remarks/del/${data.id}`, data);
  },
  getAreaInfo(id: any) {
    return request.post(`/api/user/v1/usr/areaInfo/getByParentId?parentId=${id}`);
  },
  getBindDeviceL(id: any) {
    return request({
      method: 'POST',
      url: `/api/user/v1/binding/device/list/?userId=${id}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  getOSSToken(path: any) {
    return request({
      method: 'POST',
      url: `/api/user/v1/storage/auth/token?path=${path}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  getCinfo(id: any) {
    return request({
      method: 'POST',
      url: `/api/soj/v1/buried/cinfo/${id}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default service;
