
import { Vue, Component } from 'vue-property-decorator';

@Component({ inheritAttrs: false })
export default class YkTablePoptip extends Vue {
  popVisible = false;
  showPop() {
    this.popVisible = true;
    this.$nextTick(() => {
      setTimeout(() => {
        (this.$refs.pop as HTMLDivElement).click();
      }, 100);
    });
  }
}
