import Vue from 'vue';
import cuModel from './index.vue';
import router from '@/router';
const Model = Vue.extend(cuModel);
const oDiv = document.createElement('div');
const VM = new Model({ el: oDiv }) as any;
document.body.appendChild(VM.$el);
export default {
  jump(type: any, url: any, object: any) {
    object = object || '';
    switch (type) {
      case 1:
        router.push({ path: url, query: object });
        break;
      case 2:
        router.go(-1);
        break;
    }
  },
  model(
    type: 'warn' | 'error' | 'success' | 'info' | 'question',
    headTitle: string,
    title?: string,
    content?: string,
    path?: string
  ) {
    VM.type = type;
    VM.headTitle = headTitle;
    VM.title = title;
    VM.content = content;
    VM.path = path;
    VM.show = true;
  }
};
