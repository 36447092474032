import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

const path = '/devices';
export default [
  {
    path: path,
    name: '运营',
    component: Layout,
    redirect: '/devices/index',
    meta: {
      title: '设备管理',
      icon: 'mingcute:box-line',
      code: '2ygodM4ZG5bV'
    },
    children: [
      {
        path: '/devices/index',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/devices/index.vue'),
        name: 'devices',
        meta: {
          title: '设备查询',
          code: '35pZR2v9oFA4'
        }
      },
      {
        path: '/devices/detail/:deviceId',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/devices/detail.vue'),
        name: 'deviceDetail',
        meta: {
          title: '设备列表',
          hidden: true
        }
      },
      {
        path: '/devices/versions',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/devices/versions.vue'),
        name: 'devices',
        meta: {
          title: '设备升级',
          code: '2UfGxxTuig2W'
        }
      },
      {
        path: '/devices/versions/detail/:versionId?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/devices/versionsDetail.vue'),
        name: 'versionsDetail',
        meta: {
          title: '设备升级',
          hidden: true
        }
      },
      {
        path: '/devices/log',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/devices/log.vue'),
        name: 'devices',
        meta: {
          title: '设备日志',
          code: '2QmsApcauaEb'
        }
      },
      {
        path: '/devices/log/detail/:id?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/devices/logDetail.vue'),
        name: 'deviceLogDetail',
        props: true,
        meta: {
          title: '设备日志',
          hidden: true
        }
      }
    ]
  }
] as RouteConfig[];
