import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './utils/mixins';
// 组件
import './components';
// 样式重置
// import 'normalize.css'
// 导入样式库
import ElementUI from 'element-ui';
import YkDatePicker from '@/components/YK_DatePicker';
// 重置element 样式
import '@/styles/element-variables.scss';
import locale from './assets/locale/cn';
// 导入样式
import '@/styles/index.scss';
import 'windi.css';
import '@purge-icons/generated';

import { mockXHR } from '../mock/index';

import Component from 'vue-class-component';
import moment from 'moment';
import popup from '@/components/popup/index';
interface HTMLElementPlus extends HTMLElement {
  disabled?: boolean;
}
Vue.directive('preventReClick', {
  inserted(el: HTMLElementPlus, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000);
      }
    });
  }
});

Vue.prototype.$popup = popup;
// if (process.env.NODE_ENV === 'development') {
//   mockXHR();
// }

Vue.use(ElementUI, { size: 'mini', locale });
Vue.use(YkDatePicker);
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;

// 注册路由钩子函数
Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
