
import { Component, Vue } from 'vue-property-decorator';
import SidebarItem from './SidebarItem.vue';
import variables from '@/styles/variables.scss';
import { routes } from '@/router';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';

@Component({
  components: { SidebarItem }
})
export default class SideBar extends Vue {
  get hasPermit() {
    return UserModule.hadPermit;
  }

  get userInfo() {
    return UserModule.useData;
  }

  get menuName() {
    return UserModule.menuName;
  }

  routerPath = [] as string[];

  /**
   * 获取权限路由
   */
  get routes() {
    const newRoutes = [] as any;
    routes.forEach((route: any, index: number) => {
      if (this.hasPermit(route.meta && route.meta.code)) {
        // 如果有子级并且是从首个显示路由开始
        if (route.children && this.userInfo && index === 6) {
          this.userInfo.permissionCodes.forEach((permission: any) => {
            route.children.find((item: any) => {
              if (item.meta.code === permission) {
                this.routerPath.push(item.path);
              }
            });
          });
        }
      }
      if (this.menuName && route.name === this.menuName) {
        newRoutes.push(route);
      }
    });
    UserModule.setRoutePath(this.routerPath);

    return newRoutes;
  }

  get sidebar() {
    return AppModule.sidebar;
  }

  /**
   * 获取scss变量
   */
  get variables() {
    return variables;
  }

  /**
   * 获取当前激活菜单
   */
  get activeMenu() {
    const route = this.$route;
    const { meta, path, name } = route;
    // if set path, the sidebar will highlight the path you set
    if (meta?.activeMenu) {
      return meta.activeMenu;
    }
    // 切换菜单的时候存入tab
    AppModule.SaveTab({ name: name as string, path: path, title: meta?.title });
    return meta!.title;
  }

  /**
   * 获取当前菜单是否关闭
   */
  get isCollapse() {
    return !this.sidebar.opened;
  }
}
