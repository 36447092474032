
import { Component, Vue } from 'vue-property-decorator';
import router from '@/router';
@Component({})
export default class Popup extends Vue {
  show = false;
  type = '';
  headTitle = '登出提示';
  title = '账号状态已发生改变';
  content = '请重新登录';
  path = '';
  style = {
    warn: {
      icon: 'mingcute:warning-line',
      color: '#ff9900'
    },
    error: {
      icon: 'mi:circle-error',
      color: '#f56c6c'
    },
    success: {
      icon: 'mdi:success-circle-outline',
      color: '#19be6c'
    },
    info: {
      icon: 'material-symbols:info-outline-rounded',
      color: '#409eff'
    },
    question: {
      icon: 'mdi:question-mark-circle-outline',
      color: '#ff9900'
    }
  };

  save() {
    this.show = false;
    if (this.path) {
      router.push(this.path);
    }
  }
}
