
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ inheritAttrs: false })
export default class YkTooltip extends Vue {
  @Prop({ type: String, required: false })
  content?: string;

  @Prop({ type: String, required: false })
  tagText?: string;

  @Prop({ type: String, required: false })
  textColor?: string;

  @Prop({ type: String, required: false })
  textBgColor?: string;

  @Prop({ type: Number, required: true })
  type?: number; // 是否是人工类型
}
