import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

const path = '/banner';
export default [
  {
    path: path,
    name: '运营',
    component: Layout,
    redirect: '/banner/index',
    meta: {
      title: '运营管理',
      icon: 'fa6-solid:list-check',
      code: '32spOzvnJnxn'
    },
    children: [
      {
        path: '/banner/index/:title?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/banner/index.vue'),
        name: 'banner',
        meta: {
          title: 'Banner配置',
          code: '30eUzoaCO1hB'
        }
      },
      {
        path: '/banner/detail/:bannerId?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/banner/bannerDetail.vue'),
        name: 'bannerDetail',
        meta: {
          title: 'Banner配置',
          hidden: true
        }
      },
      {
        path: '/banner/calendar',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/banner/bannerCalendar.vue'),
        name: 'calendar',
        meta: {
          title: 'Banner日历',
          hidden: true
        }
      },
      {
        path: '/banner/sort',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/banner/bannerSort.vue'),
        name: 'bannerSort',
        meta: {
          title: 'Banner配置',
          hidden: true
        }
      },
      {
        path: '/material/detail/:materialId?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/banner/materialDetail.vue'),
        name: 'materialDetail',
        meta: {
          title: 'Banner配置',
          hidden: true
        }
      },
      {
        path: '/questions/index',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/questions/index.vue'),
        name: 'questions',
        meta: {
          title: '题库管理',
          code: '35kYcv5bYMM6'
        }
      },
      {
        path: '/questions/topicDetailNone/:id?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/questions/components/topicDetailNone.vue'),
        name: '未发布题组',
        meta: {
          title: '题库管理',
          hidden: true
        }
      },
      {
        path: '/questions/topicGroupDetailUp/:id?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/questions/components/topicGroupDetailUp.vue'),
        name: '已上线发布题组',
        meta: {
          title: '题库管理',
          hidden: true
        }
      },
      {
        path: '/questions/created',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/questions/created.vue'),
        name: '新增题目',
        meta: {
          title: '题库管理',
          hidden: true
        }
      },
      {
        path: '/questions/detail/:id?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/questions/extendedDetail.vue'),
        name: '编辑题目',
        meta: {
          title: '题库管理',
          hidden: true
        }
      },
      {
        path: '/reportFile/index',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/reportFile/index.vue'),
        name: 'reportFile',
        meta: {
          title: '报告文案配置',
          code: '2UyCs2iu3v8h'
        }
      },
      {
        path: '/reportFile/factorDetail/:targetId?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/reportFile/factorDetail.vue'),
        name: '形成因素',
        meta: {
          title: '报告文案配置',
          hidden: true
        }
      },
      {
        path: '/reportFile/changeDetail/:targetId?/:apply?',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/reportFile/changeDetail.vue'),
        name: '变化报告标签',
        meta: {
          title: '报告文案配置',
          hidden: true
        }
      },
      {
        path: '/reportFile/planFirstDetail',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/reportFile/planFirstDetail.vue'),
        name: '方案-首次报告',
        meta: {
          title: '报告文案配置',
          hidden: true
        }
      },
      {
        path: '/reportFile/planChangeDetail',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/reportFile/planChangeDetail.vue'),
        name: '方案-变化报告',
        meta: {
          title: '报告文案配置',
          hidden: true
        }
      }
    ]
  }
] as RouteConfig[];
