import axios, { AxiosResponse } from 'axios';
import { Message } from 'element-ui';
import { UserModule } from '@/store/modules/user';
import { removeSkinType, removeWatermark } from '@/utils/cookies';
import router from '@/router';
import popup from '@/components/popup/index';
export enum RESPONSE_CONFIG {
  OK = 200,
  FAIL = 500,
  TIMEOUT = 5000,
  MESSAGE = 'msg',
  CODE = 'code',
  DATA = 'data',
  TOTAL = 'total',
  HEADER_TOKEN = 'accessToken',
  HEADER_LANG = 'Accept-Language'
}

const request = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL,
  baseURL:
    process.env.NODE_ENV === 'development'
      ? decodeURIComponent(process.env.VUE_APP_BASE_URL ?? process.env.REDIRECT_URL ?? '')
      : process.env.VUE_APP_BASE_URL, // url = base url + request url
  // baseURL:
  //   process.env.NODE_ENV === 'development'
  //     ? decodeURIComponent(process.env.VUE_APP_BASE_URL ?? process.env.REDIRECT_URL ?? '')
  //     : 'https://api.magm.com.cn', // url = base url + request url

  headers: {
    Accept: '*/*',
    'accept-language': 'zh-Hans-CN'
  }
  // timeout: 5000
  // withCredentials: true // send cookies when cross-domain requests
});

/**
 * 请求拦截
 */
request.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (!config.url?.includes('/uaa/oauth/token') && UserModule.token.length > 0 && UserModule.token) {
      config.headers.Authorization = 'bearer ' + UserModule.token;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

interface ResponseConfig {
  // 响应结果包含status,0 是成功
  code: number;
  msg: string;
  data: string;
}
/**
 * 响应拦截
 */
// Add a request interceptor
request.interceptors.response.use(
  function (response: AxiosResponse<ResponseConfig>) {
    // Do something before request is sent
    if (response.data.code === 200) {
      return response;
    }
    if (response.data && response.data.code !== 200) {
      Message.error(response.data.msg);
    }
    // Message.error(response.data.data);
    return Promise.reject(response);
  },
  function (error) {
    // ! 当前路由不在login时不弹 401 error，避免连续两个接口都 401 时弹两次
    // if (error.response.data.code === 401 && router.currentRoute.name !== 'Login') {
    //   Message.error(error.response.data.msg);
    //   UserModule.deltoken();
    //   router.push({ path: '/login' });
    // }
    const errorList = [512, 403];

    if (errorList.includes(error.response.data.code) && router.currentRoute.name !== 'Login') {
      UserModule.deltoken();
      removeWatermark();
      removeSkinType();
      popup.model('info', '登出提示', '账号状态已发生改变', '请重新登录', '/login');
    } else {
      Message.error(error.response.data.msg);
    }
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response interceptors
// service.interceptors.response.use(
//   response => {
//     return Promise.resolve(response)
//   },
//   error => {
//     if (error.response.status === 401 || error.response.status === 403) {
//       UserModule.ResetToken()
//       location.reload()
//     }
//     /* eslint-disable */
//     if (error.response.data.code == '2000009') {
//       return Promise.reject(error)
//     }
//     var message =
//       (error.response && error.response.data.message) ||
//       (error.message.indexOf('timeout') > -1 ? '请求超时' : error.message)
//     if (
//       navigator.userAgent.match(
//         /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//       )
//     ) {
//       Message({ message: message, type: 'error' })
//     } else {
//       console.log(error)
//       MessageBox.alert(message, '错误提示', { type: 'error' })
//     }
//     return Promise.reject(error)
//   }
// )

export default request;
