import { IUserData, IPage, ISkinType } from '@/types/dataTypes';
import Cookies from 'js-cookie';

// App
const sidebarStatusKey = 'sidebar_status';
export const getSidebarStatus = () => Cookies.get(sidebarStatusKey);
export const setSidebarStatus = (sidebarStatus: string) => Cookies.set(sidebarStatusKey, sidebarStatus);

// Token
const tokenKey = 'Admin-Token';
export const getToken = () => Cookies.get(tokenKey);
export const setToken = (token: string) => Cookies.set(tokenKey, token);
export const removeToken = () => Cookies.remove(tokenKey);

// User
const UserKey = 'User-Data';
export const getUserData = () => Cookies.get(UserKey);
export const setUserData = (data: IUserData) => Cookies.set(UserKey, JSON.stringify(data));
export const removeUserData = () => Cookies.remove(UserKey);

// Tab
const tabKey = 'current-tab';
export const getCurrentTab = () => Cookies.get(tabKey);
export const setCurrentTab = (tab: object) => Cookies.set(tabKey, JSON.stringify(tab));

// Page
const PageKey = 'Page';
export const getPage = () => Cookies.get(PageKey);
export const setPage = (data: IPage) => Cookies.set(PageKey, JSON.stringify(data));
export const removePage = () => Cookies.remove(PageKey);

// Code
const CodeKey = 'Code';
export const getCode = () => Cookies.get(CodeKey);
export const setCode = (data: string) => Cookies.set(CodeKey, JSON.stringify(data));
export const removeCode = () => Cookies.remove(CodeKey);
// Watermark
const WatermarkKey = 'Watermark';
export const getWatermark = () => Cookies.get(WatermarkKey);
export const setWatermark = (data: string) => Cookies.set(WatermarkKey, JSON.stringify(data));
export const removeWatermark = () => Cookies.remove(WatermarkKey);
// UserPath
const UserPathKey = 'User-Path';
export const getUserPath = () => Cookies.get(UserPathKey);
export const setUserPath = (data: string) => Cookies.set(UserPathKey, JSON.stringify(data));
export const removeUserPath = () => Cookies.remove(UserPathKey);
// deviceVersion
const DeviceVersionKey = 'dev-version';
export const getDeviceVersion = () => Cookies.get(DeviceVersionKey);
export const setDeviceVersion = (data: string) => Cookies.set(DeviceVersionKey, JSON.stringify(data));
export const removeDeviceVersion = () => Cookies.remove(DeviceVersionKey);
// 一级菜单名称
const MenuKey = 'MenuName';
export const getMenuName = () => Cookies.get(MenuKey);
export const setMenuName = (data: string) => Cookies.set(MenuKey, JSON.stringify(data));
export const removeMenuName = () => Cookies.remove(MenuKey);
// 肌肤问题类型
const skinTypeKey = 'skinType';
export const getSkinType = () => Cookies.get(skinTypeKey);
export const setSkinType = (data: ISkinType) => Cookies.set(skinTypeKey, JSON.stringify(data));
export const removeSkinType = () => Cookies.remove(skinTypeKey);
