import { render, staticRenderFns } from "./SpIcon.vue?vue&type=template&id=1b153bab&scoped=true&"
import script from "./SpIcon.vue?vue&type=script&lang=ts&"
export * from "./SpIcon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_sqwtrzbl6s4ypz4dmnoirtpj7i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b153bab",
  null
  
)

export default component.exports