
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ConfirmDialog extends Vue {
  @Prop({}) dialogFlag!: false;
  @Prop({ type: String, required: false, default: '操作确认' })
  title!: string;

  @Prop({ type: String, required: false })
  content?: string;

  @Prop({ type: String, required: false })
  details?: string;

  @Prop({ type: String, required: false })
  detailText?: string;

  async save() {
    this.$emit('confirmDone');
  }

  handleClose() {
    this.$emit('close');
  }
}
