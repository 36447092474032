
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';
import { AppMain, Navbar, Sidebar, Tab } from './components';
import { setWaterMark } from '@/utils/watermark';
import { getWatermark } from '@/utils/cookies';
@Component({
  components: {
    AppMain,
    Navbar,
    Sidebar,
    Tab
  }
})
export default class Layout extends Vue {
  /**
   * 绑定类名
   */
  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation
    };
  }

  /**
   * 获取侧边栏状态
   */
  get sidebar() {
    return AppModule.sidebar;
  }

  mounted() {
    const watermark = getWatermark()?.replace('"', '').replace('"', '');
    if (watermark) {
      setWaterMark(watermark);
    }
  }
}
