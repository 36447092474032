import request from '@/utils/request';
import { TIRMenuParam } from './types';

/**
 * 登录接口
 * @param userData
 * @returns
 */
export function ApiLogin(data: any) {
  const form = new FormData();
  form.append('client_id', data.client_id);
  form.append('client_secret', data.client_secret);
  form.append('grant_type', data.grant_type);
  form.append('code', data.code);
  form.append('redirectUri', data.redirectUri);
  return request({
    method: 'POST',
    url: '/api/uaa/oauth/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept-language': 'zh-Hans-CN',
      from: 1
    },
    data: form
  });
}
/**
 * 获取当前用户信息
 * @returns
 */
export function ApiGetUserinfo() {
  return request({
    method: 'GET',
    url: '/api/user/v1/userinfo'
  });
}
/**
 * 获取当前用户信息
 * @returns
 */
export function ApiPermissions() {
  return request({
    method: 'post',
    url: '/api/user/v1/permissions/listTree',
    data: {},
    headers: {
      'accept-language': 'zh-Hans-CN',
      from: 1
    }
  });
}
/**
 * 登出接口
 * @param userData
 * @returns
 */
export function ApiLogout() {
  return request({
    method: 'POST',
    url: '/api/user/v1/my/logout',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
/**
 * 获取菜单
 * @param params
 * @returns
 */
export function APIGetMenu(params: TIRMenuParam) {
  params = { ...params, page: '1', limit: '999' };
  return request({
    method: 'GET',
    url: '/wxPurchase/ProcessionList',
    params
  });
}
